export default function () {
  const { bodyLock, bodyUnlock } = useBodyLock()

  // Main Menu
  const isMenuVisible = useState<boolean>('menu', () => false)
  const isMenuBackground = useState<boolean>('menuBackground', () => false)

  function menuHide() {
    isMenuVisible.value = false
    bodyUnlock()

    submenuHide()
  }

  function menuShow(idx: number) {
    isMenuVisible.value = true
    submenuShow(idx)
  }

  function menuToggle() {
    if (!isMenuVisible.value) {
      isMenuVisible.value = true

      bodyLock()
    } else {
      menuHide()
    }
  }

  function setMenuBackground(menuBackground: boolean) {
    isMenuBackground.value = menuBackground
  }

  // Main Menu Blocks
  const isSubmenuVisible = useState<boolean>('submenu', () => false)
  const isCurrentSubmenu = useState<number>('currentSubmenu', () => -1)

  function submenuSetIdx(idx: number) {
    isCurrentSubmenu.value = idx
  }

  function submenuShow(idx: number) {
    isCurrentSubmenu.value = idx
    isSubmenuVisible.value = true
  }

  function submenuHide() {
    isSubmenuVisible.value = false
    isCurrentSubmenu.value = -1
  }

  // Mobile Only
  function submenuToggle(idx: number) {
    if (!isSubmenuVisible.value) {
      submenuShow(idx)
    } else if (idx !== isCurrentSubmenu.value) {
      submenuShow(idx)
    } else {
      isSubmenuVisible.value = false
    }
  }

  return {
    menuHide,
    menuShow,
    menuToggle,
    setMenuBackground,
    submenuShow,
    submenuHide,
    submenuToggle,
    submenuSetIdx
  }
}
